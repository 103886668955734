<template>
  <div>
  
    <v-card>
      <v-card-title>
        {{titulo}}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
  
      <v-data-table
        :headers="headers"
        :items="banks"
        :search="search"
        sort-by="number_quote"
        class="elevation-1"
        :loading="loading"
        loading-text="Consultando datos... Espere por favor"
      >
      <template v-slot:[`item.calculated_amount`]="{ item }">
        <div v-if="policy != null && policy.type_currency == 1">
          <span style="color: red;">L</span> {{ numberWithCommas(item.calculated_amount) }}
        </div>
        <div v-else>
          <span style="color: green;">$</span> {{ numberWithCommas(item.calculated_amount) }}
        </div>
      </template>
      <template v-slot:[`item.payment_amount`]="{ item }">
        <div v-if="item.payment_amount">
          {{ numberWithCommas(item.payment_amount) }}
        </div>
      </template>
      <template v-slot:[`item.payment_date`]="{ item }">
        {{ dateFormat(item.payment_date) }}
      </template>
      <template v-slot:[`item.late_days`]="{ item }">
        <div >
          {{ days_overdue(item) }}
        </div>
      </template>
      <template v-slot:[`item.balance`]="{ item }">
        <div v-if="item.payment_amount > 0">
          {{ (parseFloat((item.payment_amount).replace(/,/g, "")) - parseFloat(item.calculated_amount)).toFixed(2) }}
        </div>
      </template>
        <template v-slot:top>
          <v-toolbar flat color="white">
  
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-autocomplete
                  v-model="id_customer"
                  :items="customers"
                  item-text="name"
                  item-value="id"
                  label="Escoge un Cliente"
                  @change="getPolicies"
                  clearable
                  rounded
                  solo
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" md="4" v-if="id_customer">
                <v-form ref="form2" v-model="valid">
                  <v-autocomplete
                    v-model="policy"
                    :items="policies"
                    :rules="editedItem.policyRules"
                    item-text="policy_text"
                    item-value="id"
                    label="Escoge un Póliza"
                    @change="getBanks"
                    clearable
                    rounded
                    solo
                    return-object
                  ></v-autocomplete>
                </v-form>
              </v-col>

              <v-col cols="12" sm="12" md="2">
                <v-btn
                  color="teal"
                  class="ma-2 white--text"
                  @click="dialog = true"
                  block
                  v-if="banks.length == 0 && policy != null && policy.id > 0"
                >
                  Calcular Cuotas <v-icon right >
                    fas fa-coins
                  </v-icon>
                </v-btn>
              </v-col>

            </v-row>
  
              <!-- <v-btn
              @click="selectOption"
              v-if="!policy"
              color="primary"
              dark
              class="mb-2"
            >Nuevo Registro</v-btn> -->
  
            <!-- <v-spacer></v-spacer> -->
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Configuraciones</span>
                </v-card-title>
  
                <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid">
                    <v-row>

                      <v-col cols="12" sm="12" md="6">
                        <v-checkbox class="ml-5"
                          label="Cuota Bomberos"
                          v-model="editedItem.firefighter_split"
                          @change="checkboxs(1)"
                        ></v-checkbox>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-checkbox class="ml-5"
                          color="green"
                          label="Prima Total"
                          v-model="editedItem.total_value_split"
                          @change="checkboxs(2)"
                        ></v-checkbox>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-select
                            v-model="editedItem.way_pay"
                            :items="wayPays"
                            label="Forma de Pago"
                            item-text="name"
                            item-value="id"
                            :rules="editedItem.way_payRules"
                            @change="getQuotes"
                            chips
                            solo
                            rounded
                            clearable
                            return-object
                            required
                          >
                        </v-select>
                      </v-col>
                      
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                            v-model="editedItem.set_quote"
                            label="Número de Cuotas"
                            :rules="editedItem.set_quoteRules"
                            type="number"
                            required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
  
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                  <v-btn color="blue darken-1" text @click="calculate_quote">Guardar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- COMPONENTS -->
          <v-dialog v-model="dialogPayment" max-width="950">
            <v-card> 
              <v-spacer></v-spacer>
              <div>
                <v-btn
                  style="right:10px; top:10px;"
                  icon
                  color="error"
                  fab
                  absolute
                  @click="dialogPayment = false"
                >
                  <v-icon dark>fa-times</v-icon>
                </v-btn>
              </div>

              <Payments :key="editedItem.id" :number_quote="editedItem.id" :local_quote="editedItem.number_quote" @closeModal="closeModal($event)" v-if="dialogPayment"></Payments>
            </v-card>
          </v-dialog>
            
            <v-dialog v-model="dialogDelete" max-width="450">
              <v-card>
                <v-card-title>
                  <span>¿Desea eliminar cuota #{{editedItem.number_quote}}?</span>
                  <v-spacer></v-spacer>
                </v-card-title>
  
                <v-card-actions>
                  <v-btn color="green darken-1" text @click="close">Cancelar</v-btn>
                  <v-btn color="green darken-1" text @click="eliminar">Aceptar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template slot="body.append">
          <tr class="gray--text">
            <!-- <th class="title"></th> -->
            
            <th colspan="10" class="title">
            <v-row class="pt-2">
              <v-col cols="12" sm="12" md="3">
                Total: {{ numberWithCommas((parseFloat(sumField('calculated_amount'))).toFixed(2)) }}
              </v-col>
              <v-col cols="12" sm="12" md="3">
                Pagado: {{ numberWithCommas((parseFloat(sumField('payment_amount'))).toFixed(2)) }}
              </v-col>
              <v-col cols="12" sm="12" md="3">
                Pendiente: {{ numberWithCommas((parseFloat(sumField('calculated_amount')) - parseFloat(sumField('payment_amount'))).toFixed(2)) }}
              </v-col>
            </v-row>
            </th>
            
          </tr>
        </template>
  
        <template v-slot:[`item.action`]="{ item }">
          <v-icon color="success" class="mr-4" @click="editItem(item)">fas fa-credit-card</v-icon>
          <v-icon color="red" v-if="master==1" @click="deleteItem(item)">fas fa-trash-alt</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
  </template>
  
  <script>
  import { mapActions, mapState } from "vuex";
  import axios from "axios";
  import * as moment from 'moment';
  import jwt_decode from "jwt-decode";
  import Payments from '../views/Payments.vue';

  export default {
    components: { Payments },
    name: "collections",
    data: () => ({
      dialog: false,
      master: jwt_decode(localStorage.token).master || '',
      banks: [],
      policies: [],
      currentItems: [],
      customers: [],
      validity_date1: "",
      dialogDelete: false,
      dialogPayment: false,
      search: "",
      loading: false,
      titulo: "Cobranza",
      headers: [
        { text: "Cuota", align: "left", value: "number_quote" },
        { text: "Valor", align: "left", value: "calculated_amount" },
        { text: "Valor Pagado", align: "left", value: "payment_amount" },
        { text: "Saldo", align: "left", value: "balance" },
        { text: "Fecha de Pago", align: "left", value: "payment_date" },
        { text: "Dias de Mora", align: "left", value: "late_days" },
        { text: "Acciones", value: "action", sortable: false }
      ],
      ramos: [
        { id: 1, name: "VIDAS" },
        { id: 2, name: "DAÑOS" },
        { id: 3, name: "FIANZAS" },
      ],
      wayPays: [
        { id: 1, name: "ANUAL" },
        { id: 2, name: "MENSUAL" },
        { id: 3, name: "TRIMESTRAL" },
      ],
      policy: "",
      editedIndex: -1,
      flag_calcule: false,
      valid: true,
      id_customer: null,
      editedItem: {
        id: "",
        total_value_split: false,
        firefighter_split: false,
        number_quote: "",
        payment_date: "",
        calculated_amount: "",
        type_currency: "",
        set_quote: "",
        set_quoteRules: [
                            v => !!v || "Escribe el número de cuotas",
                            v => (v <= 12 && v > 0) || 'El número debe estar entre 1 - 12'
                         ],
        waypay: null,
        way_payRules: [v => !!v || "Escoge la forma de pago"],
      },
      defaultItem: {
        total_value_split: false,
        firefighter_split: false,
        number_quote: "",
        payment_date: "",
        calculated_amount: "",
        type_currency: "",
        set_quote: "",
        set_quoteRules: [
                            v => !!v || "Escribe el número de cuotas",
                            v => (v <= 12 && v > 0) || 'El número debe estar entre 1 - 12'
                         ],
        way_pay: null,
        way_payRules: [v => !!v || "Escoge la forma de pago"],
      }
    }),
  
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
      },
      ...mapState(["db","headers_db"]),
    },
  
    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogPayment(val) {
        val || this.close();
      }
    },
  
    methods: {
      ...mapActions(["cerrarSesion", "validateSession"]),
      // Metodos base de datos
      async calculate_quote(){
        console.log("policy", this.policy);

        if (!this.$refs.form.validate()) return;
        // {
        let num_quotes = [];
        if (this.policy == null) {
          return;
        }
        if (!this.editedItem.set_quote > 0) {
          return;
        }
        
        // try {
        //   await axios.get(this.db + 'last_renovation/collection/' + this.policy.id,
        //   {
        //     headers: this.headers_db
        //   }).then(response => {
        //     console.log('renovaciones', response.data.data);
        //     this.validity_date1 = response.data.data[0].validity_date1;
        //     this.loading = false;
        //     console.log(response.data.data);
        //   })
        //   .catch((error) => {
        //     this.validateSession(error).then((resp) => { if(resp) this.calculate_quote(); })
        //   });
        // } catch (error) {
        //   console.log(error);
        // }

        let initial_value = parseFloat(this.policy.initial_value);
        let numbers_pays = parseInt(this.editedItem.set_quote);
        let total_value = parseFloat(this.policy.total_value);
        let payment_date = (this.policy.validity_date1) ? moment(this.policy.validity_date1) : moment(this.policy.payment_date);
        let calculated_amount = 0;
        let first_quote = 0;

        if (this.editedItem.total_value_split) {
          calculated_amount = parseFloat((total_value / numbers_pays).toFixed(2));
          first_quote = calculated_amount;
        }
        else if (this.editedItem.firefighter_split) {
          let firefighter = parseFloat(this.policy.firefighter_contribution);
          let value = (total_value - initial_value) - firefighter;
          
          calculated_amount = parseFloat(((initial_value + firefighter) / numbers_pays).toFixed(2));
          first_quote = (value + calculated_amount);
        }
        else {
          calculated_amount = parseFloat((initial_value / numbers_pays).toFixed(2));
          first_quote = (total_value - initial_value) + calculated_amount;
        }

        console.log(total_value - initial_value, calculated_amount);

        const firstItem = {
          number_quote: 1,
          payment_date: payment_date.format('YYYY-MM-DD'),
          calculated_amount: first_quote.toFixed(2),
          type_currency: this.policy.type_currency
        };

        num_quotes.push(firstItem);

        for (let i = 2; i <= numbers_pays; i++) {
          const item = {
            number_quote: i,
            payment_date: payment_date.add(1, 'M').format('YYYY-MM-DD'),
            calculated_amount: calculated_amount.toFixed(2),
            type_currency: this.policy.type_currency
          };
          num_quotes.push(item);
        }
        console.log(this.num_quotes);
        await axios.post(this.db + 'collections/quotes/add', {
          id_policy: this.policy.id,
          quotes: num_quotes
        },
        {
          headers: this.headers_db
        })
        .then((response) => {
          this.close();
          // this.banks = num_quotes;
          this.getBanks();
          console.log(response.data.message);
          this.$store.dispatch("setSnackbar", {
            text: response.data.message,
          });
        })
        .catch((error) => {
          console.warn(error);
          this.num_quotes = [];
          this.validateSession(error).then((resp) => { if(resp) this.calculate_quote(); })
        });

      },

      async getBanks(){
        //this.banks = [];
        if (this.policy == null) {
          return;
        }
        await axios.get(this.db + 'collections/quotes/' + this.policy.id + '/' 
        + this.policy.validity_date1 + '/' + this.policy.validity_date2,
          {
            headers: this.headers_db
          }).then(response => {
            this.banks = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.banks = [];
            this.validateSession(error).then((resp) => { if(resp) this.getBanks(); })
          });
      },

      async getPolicies(){
        this.policies = [];
        this.banks = [];
        this.policy = null;
        
        await axios.get(this.db + 'collections/policies/' + this.id_customer,
        {
          headers: this.headers_db
        }).then(response => {
          response.data.data.forEach(item => {
            if (item.id_ramo > 0) {
              item.ramo = this.ramos.filter(doc => {
                return doc.id == item.id_ramo
              })[0].name;
            }

            item.policy_text = item.policy_number + ', ' + item.insurer + ', ' + item.branch;

          });
          this.policies = response.data.data;
          this.loading = false;
          console.log(this.policies);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getPolicies(); })
        });
      },
  
      deleteProcess(id){
  
        axios.post(this.db + 'collections/quotes/delete', { id },
        {
          headers: this.headers_db
        })
        .then((response) => {
          console.log(response);
          this.banks = this.banks.filter(doc => {
          return doc.id != id
          })
        })
        .catch((error) => {
          console.log(error);
          this.validateSession(error).then((resp) => { if(resp) this.deleteProcess(id); })
        });
  
      },

      async getCustomers(){
        await axios.get(this.db + 'list/customers',
          {
            headers: this.headers_db
          }).then(response => {
            this.customers = response.data.data;
            this.loading = false;
            console.log(response.data.data);
          })
          .catch((error) => {
            this.loading = false;
            this.validateSession(error).then((resp) => { if(resp) this.getCustomers(); })
          });
      },
      // FIN Metodos base de datos
  
  
      // Metodos Generales
      getQuotes() {
          if (this.editedItem.way_pay.id == 1)
              this.editedItem.set_quote = 1;
          else if(this.editedItem.way_pay.id == 2)
              this.editedItem.set_quote = 12;
          else if(this.editedItem.way_pay.id == 3)
              this.editedItem.set_quote = 4;
      },

      checkboxs(n) {
        if (n == 1) {
          this.editedItem.total_value_split = false
        }
        else {
          this.editedItem.firefighter_split = false
        }
      },

      closeModal(event){
        console.log("Cerrando modal Pesos");
        this.dialogPayment = event;
        this.getBanks();
      },

      dateFormat(date) {
        return moment(date).format('DD/MM/YYYY');
      },
      days_overdue(item) {
        let payment_amount = (item.payment_amount > 0) ? parseFloat(item.payment_amount) : 0;
        let calculated_amount = (item.calculated_amount > 0) ? parseFloat(item.calculated_amount) : 0;
        let balance = payment_amount - calculated_amount;
        let now = moment();
        
        if (balance < 0) {
          const daysPassed = now.diff(item.payment_date, 'days');
          return daysPassed;
        }
      },
  
      selectOption() {
        if (!this.policy) {
          this.$refs.form2.validate();
        }
      },
  
      editItem(item) {
        console.log(item);
        this.editedIndex = this.banks.indexOf(item);
        this.dialogPayment = true;
        this.editedItem = Object.assign({}, item);
      },
  
      deleteItem(item) {
        console.log(item.id);
  
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },

      sumField(key) {
        return this.banks.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
      },

      numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
  
      close() {
        this.dialog = false;
        this.dialogPayment = false;
        this.$emit('closeModal', false)
        this.dialogDelete = false;
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
          this.getBanks();
        }, 300);
      },
  
      save() {
        if (this.$refs.form.validate() && this.$refs.form2.validate()) {
          if (this.editedIndex > -1) {
            Object.assign(this.banks[this.editedIndex], this.editedItem);
            this.updateProcess(this.editedItem);
          } else {
            this.banks.push(this.editedItem);
            this.addProcess(this.editedItem);
          }
          this.close();
        }
      },
  
      eliminar() {
        this.deleteProcess(this.editedItem.id);
        this.close();
      }
    },

    async mounted() {
      await this.getCustomers();
    }
  };
  </script>
  
  